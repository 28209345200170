<template>
  <div id="ticket">
    <div class="content">
      <vue-qrcode class="qr-code" :value="getQrCode" :margin="3" />
      <div class="ticket-information">
        <div class="ticket-name">{{ ticket.cikk.cikk_nev }}</div>
        <div class="ticket-id">({{ this.ticketId }} {{ ticket.qrCodeId }})</div>
        <div class="ticket-date">
          {{ this.ticketDate }}
          <span class="ticket-date-value">
            {{ ticket.date }}
          </span>
        </div>
        <div class="ticket-date">
          {{ this.ticketPrice }}
          <span class="ticket-date-value">
            {{ ticket.cikk.cikkar_bruttoegysegar }} HUF
          </span>
        </div>
        <div class="ticket-identifier">{{ this.ticketInformationText }}</div>
        <div class="ticket-identifier">{{ this.ticketIdentifierNumber }} {{ ticket.ticketIdentifier }}</div>
      </div>
    </div>
    <div class="divider">
      <img class="divider-img" v-for='index in 3' :key='index' src="/static/images/logos/scissor-logo.png" />
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode';

export default {
  name: '',
  props: {
    ticket: {
      type: Object,
    },
    ticketInfoText: {
      type: String,
    },
  },
  components: {
    VueQrcode,
  },
  data: () => ({
    ticketId: 'jegy azonosító / Ticket ID: ',
    ticketDate: 'Dátum / date: ',
    ticketPrice: 'Ára / price: ',
    ticketInformation: 'Információ / information: ',
    ticketIdentifierNumber: 'Azonosító: ',
  }),
  created() {},
  computed: {
    getQrCode() {
      return this.ticket.qrCodeId;
    },
    ticketInformationText() {
      if (!this.ticketInfoText) {
        return this.ticketInformation;
      }
      return this.ticketInformation + this.ticketInfoText;
    },
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
#ticket {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 25px;
  }

  .qr-code {
    height: 200px;
    width: 200px;
    border: 2px solid black;
  }

  .ticket-information {
    margin-left: 15px;
  }

  .ticket-name {
    font-size: 26px;
    font-weight: 700;
  }

  .ticket-id {
    position: relative;
    top: -7.5px;
    line-height: 2.5;
    font-size: 18px;
  }

  .ticket-date {
    font-size: 20px;
  }

  .ticket-date-value {
    font-size: 20px;
    font-weight: 700;
  }

  .ticket-identifier {
    font-size: 18px;
  }

  .divider {
    height: 10px;
    width: 100%;
    margin: auto auto;
    border-top: 2px dashed black;
    opacity: .5;
  }

  .divider-img {
    position: relative;
    top: -100%;
    transform: translateY(-50%);
    height: 10px;
    width: 15px;
    margin-left: 350px;
    opacity: 1 !important;

    &:first-child {
      margin-left: 100px;
    }
  }
}
</style>
