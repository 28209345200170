<template>
  <div id="voucher-item-header">
    <div class="flex-table">
      <div class="bold-text index-item">{{ this.indexColumnText }}</div>
      <div class="bold-text item">{{ this.productNameText }}</div>
      <div class="bold-text item">{{ this.grossListPriceText }}</div>
      <div class="bold-text item">{{ this.quantityText }}</div>
      <div class="bold-text item">{{ this.grossFullPriceText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data: () => ({
    indexColumnText: '#',
    productNameText: 'Termék neve / Product name',
    grossListPriceText: 'Bruttó árjegyzéki egységár / gross list price',
    quantityText: 'Mennyiség / quantity',
    grossFullPriceText: 'Bruttó összesen / gross full',
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang='scss' scoped>
#voucher-item-header {
  --bold-text-size: 18px;
  .flex-table {
    display: flex;
    flex-flow: row wrap;

    &:first-of-type {
      border-top: 2px solid black;
    }
  }

  .item {
    width: calc(95% / 4);
    height: 100px;
    padding: 7px;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
  }

  .index-item {
    width: 5%;
    height: 100px;
    padding: 7px;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
  }

  .bold-text {
    font-size: var(--bold-text-size);
    font-weight: 700;
  }
}
</style>
