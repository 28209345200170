import { fetchData, apiURL } from './api_helper';

const readDataStore = (accessKey) => {
  const endpointURL = `${apiURL}/dataStore/read/${accessKey}`;

  return fetchData(endpointURL, 'GET');
};

const getPdf = (path) => {
  const endpointURL = `${apiURL}/pdfGenerator`;

  return fetchData(endpointURL, 'GET', {
    generatePdf: true,
    path,
  });
};

export default {
  readDataStore,
  getPdf,
};
