<template>
  <div id="voucher-item-component">
    <div class="flex-table">
      <div class="index-item">{{ index }}</div>
      <div class="item">{{ voucherItem.cikk.cikk_nev }}</div>
      <div class="item">{{ voucherItem.cikk.cikkar_bruttoegysegar }} HUF</div>
      <div class="item">{{ voucherItem.amount }}</div>
      <div class="item">
        {{ voucherItem.cikk.cikkar_bruttoegysegar * voucherItem.amount }} HUF
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    voucherItem: {
      type: Object,
    },
    index: {
      type: Number,
    },
    sumPrice: {
      type: Number,
    },
  },
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
#voucher-item-component {
  .flex-table {
    display: flex;
    flex-flow: row wrap;
  }

  .item {
    width: calc(95% / 4);
    height: 70px;
    padding: 7px;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
  }

  .index-item {
    width: 5%;
    height: 70px;
    padding: 7px;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
  }
}
</style>
